import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

const Header = () => {
  const navigate = useNavigate();

  const [isScrolling, setisScrolling] = useState(false);

  window.addEventListener("scroll", function () {
    const scrollY = window.scrollY;
    if (scrollY <= 0) {
      setisScrolling(false);
    } else {
      setisScrolling(true);
    }
  });

  const handleButtonClick = () => {
    navigate('/ContactUs');
  };

  
  const handleTestButtonClick = () => {
    navigate('/Testimonials');
  };

  
  const handleHolidayButtonClick = () => {
    navigate('/HalalHoliday');
  };

  const handleHajjButtonClick = () => {
    navigate('/Hajj');
  };

  const handleUmrahButtonClick = () => {
    navigate('/Umrah');
  };

  const handleHomeButtonClick = () => {
    navigate('/');
  };


  return (
    <Wrapper>
      <div className="topline"></div>
      <div
        className={
          isScrolling
            ? "Opacitybackground-scrolling-class"
            : "Opacitybackground"
        }
      ></div>

      <div className={isScrolling ? "Inner-scrolling-class" : "Inner"}>
        <div className="socialmedia">
          <div className="iconInner">
            <FacebookIcon
              className={!isScrolling ? "icon" : "icon-scrolling-class"}
            />
            <TwitterIcon
              className={!isScrolling ? "icon" : "icon-scrolling-class"}
            />
            <YouTubeIcon
              className={!isScrolling ? "icon" : "icon-scrolling-class"}
            />
          </div>
        </div>
        <div className="name">
          <div className={isScrolling ? "title-scrolling-class" : "title"}>
            Ocean Breeze
          </div>
          <div
            className={isScrolling ? "subtitle-scrolling-class" : "subtitle"}
          >
            Travel
          </div>
        </div>
        <div className="contact">
          <div className={isScrolling ? "number-scrolling-class" : "number"}>
            Call us <span className="digits">0740 338 4893</span>
          </div>
        </div>
      </div>

      <div className={isScrolling ? "navigator-scrolling-class" : "navigator"}>
        <div className="navigatorInner">
          <div className={isScrolling ? "button-scrolling-class" : "button"} onClick={handleHomeButtonClick}>
            Home
          </div>
          <div className="dividerHolder">
            <div
              className={isScrolling ? "divider-scrolling-class" : "divider"}
            ></div>
          </div>

          <div className={isScrolling ? "button-scrolling-class" : "button"} onClick={handleUmrahButtonClick}>
            Umrah
          </div>
          <div className="dividerHolder">
            <div
              className={isScrolling ? "divider-scrolling-class" : "divider"}
            ></div>
          </div>
          <div className={isScrolling ? "button-scrolling-class" : "button"} onClick={handleHajjButtonClick}>
            Hajj
          </div>
          <div className="dividerHolder">
            <div
              className={isScrolling ? "divider-scrolling-class" : "divider"}
            ></div>
          </div>
          <div className={isScrolling ? "button-scrolling-class" : "button"} onClick={handleHolidayButtonClick}>
            {" "} 
            Holiday
          </div>
          <div className="dividerHolder">
            <div
              className={isScrolling ? "divider-scrolling-class" : "divider"}
            ></div>
          </div>
          <div className={isScrolling ? "button-scrolling-class" : "button"} onClick={handleTestButtonClick}>
            Testimonials
          </div>
          <div className="dividerHolder">
            <div
              className={isScrolling ? "divider-scrolling-class" : "divider"}
            ></div>
          </div>
       
          <div className={isScrolling ? "button-scrolling-class" : "button"} onClick={handleButtonClick}>
            Contact Us
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  height: 180px;
  width: 100%;
  position: fixed;
  z-index: 10;

  .Inner {
    height: 75%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: height 0.3s ease;
  }
  .Inner-scrolling-class {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: height 0.3s ease;
  }
  .socialmedia {
    height: 60%;
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    :hover {
      cursor: pointer;
    }
  }
  .navigator {
    height: 25%;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.3s ease;
  }
  .navigator-scrolling-class {
    height: 15%;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.3s ease;
  }
  .navigatorInner {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    width: 60%;
    height: 75%;
  }
  .button {
    color: white;
    font-weight: bold;
    font-size: 16px;
    :hover {
      cursor: pointer;
    }
    transition: color 0.3s ease;
    font-family: "Montserrat", sans-serif;
  }
  .button-scrolling-class {
    color: black;
    font-weight: bold;
    font-size: 16px;
    :hover {
      cursor: pointer;
    }
    transition: color 0.3s ease;
    :hover {
      cursor: pointer;
    }

  }
  .button:hover {
  cursor: pointer;
}
.button-scrolling-class:hover {
  cursor: pointer;
}
  .dividerHolder {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .divider {
    height: 50%;
    width: 1px;
    background-color: white;
    opacity: 0.55;
    transition: color 0.3s ease;
  }
  .divider-scrolling-class {
    height: 50%;
    width: 1px;
    background-color: black;
    opacity: 0.55;
    transition: color 0.3s ease;
  }

  .name {
    height: 100%;
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    transition: opacity 0.3s ease;
  }
  .name-scrolling-class {
    height: 60%;
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    transition: opacity 0.3s ease;
  }
  .contact {
    height: 60%;
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
  }
  .icon {
    color: whitesmoke;
    font-size: 26px;
    transition: color 0.3s ease;
  }
  .icon-scrolling-class {
    color: black;
    transition: color 0.3s ease;
  }

  .number {
    color: whitesmoke;
    font-size: 25px;
    transition: color 0.3s ease;
    font-family: "Montserrat", sans-serif;
  }
  .number-scrolling-class {
    color: black;
    font-size: 25px;
    transition: color 0.3s ease;
  }
  .digits {
    font-weight: bold;
  }
  .title {
    color: white;
    font-size: 50px;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: color 0.3s ease;
  }
  .title-scrolling-class {
    color: black;
    font-size: 35px;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: color 0.3s ease;
  }

  .subtitle {
    color: white;
    font-size: 40px;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: font 0.2s ease;
  }
  .subtitle-scrolling-class {
    color: black;
    font-size: 0px;
    transition: font 0.2s ease;
  }
  .iconInner {
    width: 125px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .Opacitybackground-scrolling-class {
    position: absolute;
    height: 80%;
    width: 100%;
    opacity: 1;
    background: white;
    z-index: -1;
    transition: color 0.3s ease;
    transition: height 0.3s ease;
  }
  .Opacitybackground {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.4;
    z-index: -1;
    transition: color 0.3s ease;
    transition: height 0.3s ease;
  }
  .topline {
    height: 09px;
    width: 100%;
    background-color: #de007a;
  }
`;
export default Header;
