import React,{useEffect} from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TestimonialCard from "../components/TestimonialCard";

function Testimonials() {
  const handleScroll = () => {
    window.scrollBy({
      top: 550,
      left: 0,
      behavior: "smooth",
    });
  };

  const users = [
    { id: 1,category:"UMRAH",avatar:true, name: " Hamad M.",date:"January",star:5,comment:"I recently had the pleasure of booking my Umrah journey with Ocean Breeze Travel, and I must say, the entire experience was nothing short of exceptional From the initial consultation to the final details, the team ensured everything was taken care of. I was able to focus on my spiritual journey without any worries about logistics. Hamad M" },
    { id: 2,category:"UMRAH",avatar:true, name: " Saqib S.",date:"January",star:5,comment:"Ocean Breeze Travel made my Umrah pilgrimage a truly memorable experience. The attention to detail and the personalised service I received were beyond my expectations. They handled everything with such professionalism and care that I felt completely at ease. I will definitely be booking with them again in the future. Kalidu S." },
    { id: 3,category:"UMRAH",avatar:false, name: "Shoaib T.",date:"Feburary",star:5,comment:"My Umrah experience with Ocean Breeze Travel was absolutely fantastic. The entire trip was wellorganized, from flights to accommodation, and everything went smoothly. The team was always available to answer any questions, making me feel supported throughout the journey. I couldn't have asked for a better experience. Bilay T." },
    { id: 4,category:"UMRAH",avatar:true, name: "Abdul Hameed",date:"Feburary",star:5,comment:"I am extremely grateful to Ocean Breeze Travel for making my Umrah trip so seamless and enjoyable. They took care of every detail, allowing me to focus entirely on the spiritual aspects of the pilgrimage. Their commitment to providing top-notch service is truly commendable. I highly recommend them to anyone planning an Umrah trip.  Abdulqadir A" },
    { id: 5,category:"UMRAH",avatar:false, name: " Asifa R.",date:"Feburary",star:5,comment:"ooking my Umrah trip with Ocean Breeze Travel was one of the best decisions I’ve made. The service was impeccable, and they ensured every aspect of my journey was perfect. From the moment I landed to my return home, everything was so well-coordinated. I felt truly blessed to have such a worry-free experience. Amina R." },
    { id: 6,category:"UMRAH",avatar:true, name: "Yesmin A.",date:"March",star:5,comment:"I cannot thank Ocean Breeze Travel enough for the outstanding service they provided during my Umrah pilgrimage. Their attention to detail and prompt responses to my inquiries made me feel confident and reassured throughout the journey. It was a truly spiritual and stress-free experience, thanks to their professionalism. Yusuf A." },
    { id: 7,category:"UMRAH",avatar:false, name: "Tooba K.",date:"March",star:5,comment:"My experience with Ocean Breeze Travel was nothing short of amazing. The team went above and beyond to ensure that my Umrah trip was comfortable and fulfilling. Their dedication to customer satisfaction is evident in every aspect of their service. I highly recommend them to anyone planning their Umrah. Zainab K." },
    { id: 8,category:"UMRAH",avatar:true, name: "Farhan H.",date:"March",star:5,comment:"Ocean Breeze Travel exceeded my expectations in every way. They made my Umrah pilgrimage a smooth and memorable journey. From the initial booking to the actual travel, everything was handled with such care and precision. I could focus on my prayers and spiritual reflection, knowing that all the logistics were in capable hands. Ibrahim H."},
    
  ];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);


  return (
    <Wrapper>
      <Header />
      <div className="upper-divs">
        <div className="holders">
          <div className="large-text">
           Testimonials
          </div>
          <KeyboardArrowDownIcon className="down-icon" onClick={handleScroll} />
          {/* <img
            className="bg-image"
            src={
              "https://img.freepik.com/free-vector/feedback-customer-review-concept-rating-client-satisfaction-service-app-product-vector-flat-illustration-quality-rate-with-people-holding-gold-stars-like-symbols_107791-9835.jpg?t=st=1721393065~exp=1721396665~hmac=622c5f83998ad3da47405541429b9e07b9f53458e07c712498ba42a9654f50f5&w=1380"
            }
          /> */}
          <div className="blur-bg" />
        </div>
      </div>

      <div className="review-outer">
        <div className="review-main">
          {users.map((user) => (
            <TestimonialCard key={user.id} category={user.category} avatar={user.avatar} date={user.date} star={user.star} comment={user.comment} name={user.name} />
          ))}
        </div>
      </div>

      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;

  .bg-image {
    height: 100%;
    width: 100%;
    z-index: -2;
    position: absolute;
    object-fit: cover;
  }

  .review-outer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }
  .review-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;

    justify-content: space-between;
    width: 80%;
  }

  .blur-bg {
    height: 100%;
    width: 100%;
    background-color: black;
    position: absolute;
    z-index: -1;
    opacity: 0.5;
  }
  .large-text {
    font-size: 35px;
    width: 70%;
    text-align: center;
    color: white;
    margin-top: 7.5%;
    font-family: "Montserrat", sans-serif;
  }

  .upper-divs {
    height: 70vh;
    width: 100%;
  }
  .down-icon {
    font-size: 75px;
    margin-top: 45px;
    color: white;
    background-color: #1328bc;
    border-radius: 50%;
    transition: all 0.3s ease;
    background-color: #183b7d;

  }
  .down-icon:hover {
    cursor: pointer;
    background-color: white;
    transition: all 0.3s ease;
    color: #1328bc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
  }

  .holders {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #0b155f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1f4995;

  }
  @media (max-width: 768px) {
    .upper-divs {
      height: 70vh;
      width: 100%;
      clip-path: none;
    }

    .holders {
      clip-path: none;
      background-color: #1328bc;
    }

    .large-text {
      font-size: 20px;
      width: 95%;
      font-family: "Montserrat", sans-serif;
    }
  }
`;
export default Testimonials;
