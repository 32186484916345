import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UmrahPrompt from "../components/UmrahPrompt";

import React, { useEffect } from "react";

function Umrah() {
  const handleScroll = () => {
    window.scrollBy({
      top: 500,
      left: 0,
      behavior: "smooth",
    });
  };

  const Umrah1 = [
    {
      id: 1,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 899,
      title: "October Umrah Package",
      stars: 5,
      depart: "05 Oct 2024",
      arrival: "15 Oct 2024",
    },
    {
      id: 2,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 999,
      title: "February Umrah Package",
      stars: 5,
      depart: "10 Feb 2024",
      arrival: "24 Feb 2024",
    },
    {
      id: 3,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 1099,
      title: "December Umrah Package",
      stars: 5,
      depart: "20 Dec 2024",
      arrival: "30 Dec 2024",
    },
  ];

  const Umrah2 = [
    {
      id: 1,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 799,
      title: "October Umrah Package",
      stars: 4,
      depart: "12 Oct 2024",
      arrival: "22 Oct 2024",
    },
    {
      id: 2,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 899,
      title: "February Umrah Package",
      stars: 4,
      depart: "12 Feb 2024",
      arrival: "24 Feb 2024",
    },
    {
      id: 3,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 999,
      title: "December Umrah Package",
      stars: 4,
      depart: "20 Dec 2024",
      arrival: "30 Dec 2024",
    },
  ];

  const Umrah3 = [
    {
      id: 1,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 699,
      title: "October Umrah Package",
      stars: 3,
      depart: "14 Oct 2024",
      arrival: "25 Oct 2024",
    },
    {
      id: 2,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 799,
      title: "February Umrah Package",
      stars: 3,
      depart: "13 Feb 2024",
      arrival: "26 Feb 2024",
    },
    {
      id: 3,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 899,
      title: "December Umrah Package",
      stars: 3,
      depart: " Dec 2024",
      arrival: "30 Dec 2024",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <Wrapper>
      <Header />
      <div className="upper-divs">
        <div className="holders">
          <div className="large-text">
          Umrah
          </div>
          <KeyboardArrowDownIcon className="down-icon" onClick={handleScroll} />
          <img
            className="bg-image"
            src="https://images.pexels.com/photos/18351141/pexels-photo-18351141/free-photo-of-crowd-of-pilgrims-around-kaaba.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          />
          <div className="blur-bg" />
        </div>
      </div>

      <UmrahPrompt title={"Explore 5 Star Packages"} data={Umrah1} />

      <UmrahPrompt title={"Explore 4 Star Packages"} data={Umrah2} />

      <UmrahPrompt title={"Explore 3 Star Packages"} data={Umrah3} />

      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;


  .bg-image {
    height: 100%;
    width: 100%;
    z-index: -2;
    position: absolute;
    object-fit: cover;
  }
  .blur-bg {
    height: 100%;
    width: 100%;
    background-color: black;
    position: absolute;
    z-index: -1;
    opacity: 0.5;
  }
  .package-holder {
    height: 100vh;
    width: 100%;
    background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .large-text {
    font-size: 35px;
    width: 70%;
    text-align: center;
    color: white;
    margin-top: 7.5%;
    /* font-weight: 100; */
    font-family: "Montserrat", sans-serif;
  }

  .upper-divs {
    height: 70vh;
    width: 100%;
    
  }
  .down-icon {
    font-size: 75px;
    margin-top: 45px;
    color: white;
    background-color: #183b7d;
    border-radius: 50%;
    transition: all 0.3s ease;
    
  }
  .down-icon:hover {
    cursor: pointer;
    background-color: white;
    transition: all 0.3s ease;
    color: #1328bc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
  }

  .holders {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #0b155f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1f4995;


  }

  @media (max-width: 768px) {
    .upper-divs {
      height: 70vh;
      width: 100%;
      clip-path: none;
    }

    .holders {
      clip-path: none;
      background-color: #1328bc;
    }

    .large-text {
      font-size: 20px;
      width: 95%;
      font-family: "Montserrat", sans-serif;
    }
  }
`;
export default Umrah;
