import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HolidayPrompt from "../components/HolidaysPrompt"
import React, { useEffect } from "react";



function HalalHoliday() {

  const handleScroll = () => {
    window.scrollBy({
      top: 600,
      left: 0,
      behavior: "smooth",
    });
  };

  
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  // }, []);


  const Umrah1 = [
    {
      id: 1,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 999,
      title: "Turkey Halal Escape",
      stars: 5,
      depart: "25 Jul 2024",
      arrival: "05 Aug 2024",
    },
    {
      id: 2,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 1499,
      title: "Maldives Luxury Halal Retreat",
      stars: 5,
      depart: "10 Aug 2024",
      arrival: "24 Aug 2024",
    },
    {
      id: 3,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice:899,
      title: "Moroccan Cultural Adventure",
      stars: 5,
      depart: "10 Oct 2024",
      arrival: "20 Oct 2024",
    },
  ];

  const Umrah2 = [
    {
      id: 1,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 799,
      title: " Spain Halal Heritage Tour",
      stars: 4,
      depart: "25 Jul 2024",
      arrival: "05 Aug 2024",
    },
    {
      id: 2,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 1299,
      title: " Malaysia Family Halal Holiday",
      stars: 4,
      depart: "10 Aug 2024",
      arrival: "24 Aug 2024",
    },
    {
      id: 3,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice:799,
      title: " Jordan Historical Journey",
      stars: 4,
      depart: "10 Oct 2024",
      arrival: "20 Oct 2024",
    },
  ];

  const Umrah3 = [
    {
      id: 1,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 599,
      title: "Antalya Family Package",
      stars: 3,
      depart: "10 Jun 2024",
      arrival: "24 Jun 2024",
    },
    {
      id: 2,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 699,
      title: "Cairo Exploration Package",
      stars: 3,
      depart: "13 Feb 2024",
      arrival: "26 Feb 2024",
    },
    {
      id: 3,
      image: "https://img.freepik.com/free-photo/woman-with-hat-sitting-chairs-beach-beautiful-tropical-beach-woman-relaxing-tropical-beach-koh-nangyuan-island_335224-1110.jpg?t=st=1725202070~exp=1725205670~hmac=9922e163883db0ee683e505123fcd583d948f30b08701a9d6c602174aebe6b21&w=900",
      startingPrice: 499,
      title: "Turkey Journey Package",
      stars: 3,
      depart: " Dec 2024",
      arrival: "30 Dec 2024",
    },
  ];

  return (
    <Wrapper>
      <Header />
      <div className="upper-divs">
        <div className="holders">
          <div className="large-text">
            Holiday
          </div>
          <KeyboardArrowDownIcon className="down-icon" onClick={handleScroll} />
          <img className="bg-image" src="https://images.stockcake.com/public/f/f/2/ff267182-f8f3-420a-8dcb-63784c9635f8/istanbul-evening-market-stockcake.jpg"/>
            <div className="blur-bg"/>
        </div>

      </div>

      <HolidayPrompt title={"Explore 5 Star Holiday Packages"} data={Umrah1}/>

      <HolidayPrompt title={"Explore 4 Star Holiday Packages"} data={Umrah2}/>

      <HolidayPrompt title={"Explore 3 Star Holiday Packages"} data={Umrah3}/>

      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;

  .bg-image{
    height: 100%;
    width:100%;
    z-index: -2;
    position: absolute;
    object-fit: cover;

  }
  .blur-bg{
    height: 100%;
    width: 100%;
    background-color: black;
    position: absolute;
    z-index: -1;
    opacity: 0.5;
  }
  .package-holder {
    height: 100vh;
    width: 100%;
    background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .large-text {
    font-size: 35px;
    width: 70%;
    text-align: center;
    color: white;
    margin-top: 7.5%;
    /* font-weight: 100; */
    font-family: "Montserrat", sans-serif;

  }

  .upper-divs {
    height: 70vh;
    width: 100%;
    }
  .down-icon {
    font-size: 75px;
    margin-top: 45px;
    color: white;
    background-color:#1328bc;
    border-radius: 50%;
    transition: all 0.3s ease;
    background-color: #183b7d;
  }

  .down-icon:hover {
    cursor: pointer;
    background-color:white;
    transition: all 0.3s ease;
    color: #1328bc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
  }

  .holders {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #0b155f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1f4995;

  }
  @media (max-width: 768px) {
    .upper-divs {
      height: 70vh;
      width: 100%;
      clip-path: none;
    }
    .holders {
      clip-path: none;
      background-color: #1328bc;
    }
    .large-text {
      font-size: 20px;
      width: 95%;
      font-family: "Montserrat", sans-serif;
    }
  }
`;
export default HalalHoliday;
