import React from "react";
import styled from "styled-components";
import Rating from "@mui/material/Rating";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";

function Gallery() {
  return (
    <Wrapper>
      <img
        className="backimage"
        src="https://images.pexels.com/photos/5798526/pexels-photo-5798526.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
      />
      <div className="blur-bg"></div>

      <div className="main">
        <div className="inner">
          <div className="left-div">
            <div className="textholder">
              <div className="subtext">UMRAH 2024</div>
              <div className="maintext">
                Book your Umrah journey with ease through us.
              </div>

              <div className="divider"></div>
              <div className="company">Cross Breeze Travel</div>
            </div>
          </div>

          <div className="right-div">
            <div className="right-inner">
              <div className="profile-picture">
                <img
                  src="https://images.pexels.com/photos/18996599/pexels-photo-18996599/free-photo-of-believers-praying-in-the-courtyard-of-the-great-mosque-of-mecca.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  className="image"
                />
              </div>

              <Rating
                name="size-large"
                defaultValue={5}
                size="large"
                className="rating"
              />
              <div className="upper">
                <div className="upper-maintext">Umrah Serah Tour</div>
                <div className="upper-midtext">(08 Feb 2024)</div>
                <div className="upper-subtext">5 STAR PACKAGE</div>
              </div>
              <div className="middle">
                <div className="middle-bg" />

                <div className="middle-inner">
                  <div className="middle-inner-left">
                    <div className="inner-head">
                      Depart <FlightTakeoffIcon className="planeleft" />{" "}
                    </div>
                    <div className="inner-date">08 Jul</div>
                  </div>
                  <div className="middle-inner-right">
                    <div className="inner-head">
                      {" "}
                      Arrival <FlightLandIcon className="planeleft" />{" "}
                    </div>
                    <div className="inner-date">18 Jul</div>
                  </div>
                </div>
              </div>
              <div className="lower">
                <div className="lower-left">
                  <div className="lower-left-title">DURATION</div>
                  <div className="lower-left-subtitle">10 Days</div>
                </div>
                <div className="lower-divider"></div>
                <div className="lower-right">
                  <div className="lower-left-title">PRICE FROM</div>
                  <div className="lower-left-subtitle">£ 999</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100vh;
  width: 100%;
  position: relative;
  margin-top: 50px;
  .image {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .backimage {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1;
  }
  .main {
    height: 100%;
    width: 100%;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .inner {
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .rating {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 60px;
    z-index: 5;
  }
  .left-div {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  .right-div {
    height: 100%;
    width: 40%;
    position: relative;
    box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.3);
  }
  .right-inner {
    height: 120%;
    width: 100%;
    top: -10%;
    position: absolute;
    border-radius: 30px 30px 0px 0px;
  }
  .profile-picture {
    height: 200px;
    width: 200px;
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
  }
  .upper {
    width: 100%;
    height: 50%;
    background-color: #214da6;
    border-radius: 30px 30px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .upper-maintext {
    font-size: 55px;
    text-align: center;
    color: white;
    font-weight: 700;
    margin-top: 5%;
  }
  .upper-midtext {
    font-size: 55px;
    color: white;
    font-weight: 100;
  }
  .upper-subtext {
    font-size: 25px;
    letter-spacing: 5px;
    font-weight: 800;
    color: white;
    margin-top: 10%;
  }
  .planeleft {
    font-size: 25px;
  }
  .inner-head {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    font-family: "EB Garamond", serif;
  }
  .inner-date {
    font-size: 25px;
    font-weight: 100;
  }

  .middle {
    height: 25%;
    width: 100%;
    position: relative;
  }
  .middle-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    z-index: 2;
    opacity: 0.15;
  }
  .middle-inner {
    z-index: 3;
    height: 100%;
    width: 100%;
    color: white;
    position: absolute;
    display: flex;
  }
  .middle-inner-left {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 6px solid rgb(255, 255, 255, 0.5);
  }
  .middle-inner-right {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .lower {
    height: 25%;
    width: 100%;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .lower-left {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lower-divider {
    height: 50%;
    width: 1px;
    background-color: black;
    opacity: 0.75;
  }
  .lower-left-title {
    font-size: 23px;
    font-weight: 1000;
    letter-spacing: 2px;
  }

  .lower-left-subtitle {
    font-size: 50px;
    font-weight: 100;
    letter-spacing: 2px;
  }
  .lower-right {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .textholder {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding-left: 5%;
  }
  .maintext {
    font-size: 60px;
    font-weight: 1000;
    color: white;
  }
  .subtext {
    font-size: 20px;
    font-weight: 1000;
    color: white;
  }
  .divider {
    width: 100%;
    height: 2px;
    background-color: white;
    margin-top: 5px;
    margin-bottom: 10px;
    opacity: 0.85;
  }
  .company {
    font-size: 30px;
    font-weight: 1000;
    color: white;
    letter-spacing: 5px;
  }
  .blur-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.2;
    z-index: 1;
  }
`;

export default Gallery;
