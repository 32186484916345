import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import ReviewCard from "./ReviewCard";

function Reviews() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className="title">Reviews</div>
      <div className="main">
        <div className="leftblur"></div>
        <div className="rightblur"></div>

        <div className="cardholder">
          <Swiper
            slidesPerView={"auto"}
            centeredSlides={true}
            spaceBetween={30}
           
            navigation={true} // Enable navigation arrows
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              {" "}
              <ReviewCard />{" "}
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <ReviewCard />{" "}
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <ReviewCard />{" "}
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <ReviewCard />{" "}
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <ReviewCard />{" "}
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <ReviewCard />{" "}
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <ReviewCard />{" "}
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <ReviewCard />{" "}
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="button">
          <div className="buttontext">READ ALL REVIEW</div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;
  position: relative;
 



  /* clip-path: polygon(
    69% 7.9%,
    100% 10%,
    100% 40%,
    100% 100%,
    68% 100%,
    32% 100%,
    0 100%,
    0 10%,
    26% 7.9%
  ); */
  .buttontext {
    text-align: center;
    font-weight: 500;
    letter-spacing: 2px;
    transition: all 0.3s;

  }
  .button {
    height: 13px;
    width: 170px;
    border: 2px solid black;
    padding: 1%;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: all 0.3s;
    transition: border 0.1s;
  }
  .button:hover .buttontext {
    color: white;
    transition: all 0.3s;
    cursor: pointer;

}

.button:hover  {
  border: none;
  background-color: #D90479;
  transition: all 0.3s;
  transition: border 0.1s;
  cursor: pointer;

}
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-bottom: 5%;
    z-index: 5;
  }

  .leftblur {
    height: 50%;
    width: 15%;
    background-color: white;
    position: absolute;
    left: 0px;
    z-index: 10;
    filter: blur(130px);
  }
  .rightblur {
    height: 50%;
    width: 15%;
    background-color: white;
    position: absolute;
    right: 0px;
    z-index: 10;
    filter: blur(150px);
  }
  .title {
    font-size: 70px;
    color: #454a46;
    text-align: center;
    font-family: "EB Garamond", serif;
    letter-spacing: 2px;
    padding-top: 10%;
  }
  .cardholder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 150px;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide {
    width: 60%;
  }
`;
export default Reviews;
