import styled from "styled-components";
import Rating from "@mui/material/Rating";

function UmrahCard(props) {
  const phoneNumber = "447466710000";

  return (
    <Wrapper>
      <div className="imageHolder">
        <img src={props.image} className="upper-image" />
      </div>
      <div className="upper-div">
        <div className="textholder">
          <div className="card-title">{props.title}</div>
          <div className="card-subtitle">{props.stars} STAR PACKAGE</div>
        </div>
      </div>
      <div className="lower-div">
        <div className="lower-upper">
          <div className="arrival">
            <div className="arrival-text">Depart </div>
            <div className="arrival-subtext">{props.depart}</div>
          </div>
          <div className="depart">
            <div className="arrival-text">Arrival </div>
            <div className="arrival-subtext">{props.arrival}</div>
          </div>
        </div>
        <div className="lower-lower">
          <div className="days">
            <div className="day-title">DURATION</div>
            <div className="day-subtitle">{props.days} Days</div>
          </div>
          <div className="price">
            <div className="day-title">PRICE FROM</div>
            <div className="day-subtitle">£ {props.startingPrice}</div>
          </div>
        </div>

        <a
          href={`https://wa.me/${phoneNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="book-now">Book Now</div>
        </a>
      </div>
      <Rating
        name="size-large"
        defaultValue={props.stars}
        size="large"
        className="rating"
        readOnly
      />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 29%;
  height: 500px;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  margin-top: 100px;
  font-family: "Montserrat", sans-serif;

  .imageHolder {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    top: -75px;
    position: absolute;
  }
  .rating {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
  }
  .upper-image {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  .upper-div {
    height: 50%;
    width: 100%;
    border-radius: 50px 50px 0px 0px;

    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    background-color: #0039a9;
    background-color: #1f4995;

  }
  .textholder {
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .card-subtitle {
    font-size: 17px;
    font-weight: 500;
    color: white;
    letter-spacing: 2px;
  }
  .card-title {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: white;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
  }

  .book-now {
    position: absolute;
    z-index: 9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 50px;
    height: 50px;
    width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: white;
    transition: all 0.3s ease;
  }
  .book-now:hover {
    cursor: pointer;
    background-color: white;
    transition: all 0.3s ease;
    color: #1328bc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* transform: scale(1.1); */
  }

  .lower-div {
    height: 50%;
    width: 100%;
    position: relative;
    
  }

  .lower-upper {
    width: 100%;
    height: 50%;
    background-color: #1f4995;

    display: flex;
  }
  .arrival {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 50%;
    height: 100%;
  }
  .arrival-text {
    font-size: 17px;
    font-weight: 500;
    color: white;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
  }
  .arrival-subtext {
    font-size: 17px;
    margin-top: 10px;
    font-weight: 500;
    color: white;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
  }
  .depart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 50%;
    height: 100%;
  }
  .lower-lower {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #0039a9;
    border-radius: 0px 0px 50px 50px;
    background-color: #1f4995;

  }
  .days {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .day-title {
    font-size: 17px;
    letter-spacing: 2px;
    font-weight: 500;
    color: white;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
  }
  .day-subtitle {
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: 500;
    color: white;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
  }
  .price {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  @media (max-width: 768px) {
    width: 85%;
    height: 550px;
  }
`;
export default UmrahCard;
