import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Newscard from "./Newscard";

function NewscardHolder() {
  const navigate = useNavigate();

  return (
    <Wrapper>
         <div className="title">Latest news </div>
      <div className="main">
       
        <div className="cardholder">
          <Newscard
            title={"Register your Interest"}
            subtitle={"Assalamu alaikum Good news: Update for Hajj 2023 Alhamdulillah, we are pleased to announce that"}
            image={
              "https://st.depositphotos.com/1003580/1217/i/450/depositphotos_12179888-stock-photo-makkah-kaaba-door-with-verses.jpg"
            }
            date={"Jan 6th 2023"}
          />
           <Newscard
            title={"Register your Interest"}
            subtitle={"Assalamu alaikum Good news: Update for Hajj 2023 Alhamdulillah, we are pleased to announce that"}
            image={
              "https://st.depositphotos.com/1003580/1217/i/450/depositphotos_12179888-stock-photo-makkah-kaaba-door-with-verses.jpg"
            }
            date={"Dec 18th 2023"}
          />
            <Newscard
            title={"Hajj 2022 / 1443 Latest Updates"}
            subtitle={"Assalamu alaikum Good news: Update for Hajj 2023 Alhamdulillah, we are pleased to announce that"}
            image={
              "https://st.depositphotos.com/1003580/1217/i/450/depositphotos_12179888-stock-photo-makkah-kaaba-door-with-verses.jpg"
            }
            date={"Feb 6th 2023"}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  
  .main {
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    align-items: center;
    align-content: center;
    
  }
  .title{
    font-size: 45px;
    color: #454A46;
    text-align: center;
    font-family: "EB Garamond", serif;
    
    letter-spacing: 2px;
  }
  .cardholder {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10%;
  }
`;
export default NewscardHolder;
