import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';



function Ourpartners() {
  const navigate = useNavigate();

  return (
    <Wrapper>
        <div className="main">

       <div className="title">Our Partners</div>
 <Swiper
        slidesPerView={4}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        autoplay={{ delay: 10 }} 
        className="mySwiper"
        
      >
        <SwiperSlide>
            <img className="logo" src="https://lbcdn.airpaz.com/cdn-cgi/image/w=530,h=390,f=webp/hotelimages/3840218/emaar-international-mecca-11543b7355692eafd49f12c3cff3d182.jpg"/>
        </SwiperSlide>
        <SwiperSlide>
            <img className="logo" src="https://logowik.com/content/uploads/images/swissotel-hotels-resorts3100.jpg"/>
        </SwiperSlide>
        <SwiperSlide>
            <img className="logo" src="https://logowik.com/content/uploads/images/hilton-hotels-resorts4207.jpg"/>
        </SwiperSlide>
        <SwiperSlide>
            <img className="logo" src="https://logowik.com/content/uploads/images/moevenpick5060.logowik.com.webp"/>
        </SwiperSlide>
        <SwiperSlide>
            <img className="logo" src="https://www.eyeofdubai.ae/includes/image.php?image=/directory/images/2022/12/f26185e0ec76.jpg&width=230&height=160"/>
        </SwiperSlide>
        <SwiperSlide>
            <img className="logo" src="https://seekvectorlogo.com/wp-content/uploads/2018/01/pullman-hotels-and-resorts-vector-logo-small.png"/>
        </SwiperSlide>
        <SwiperSlide>
            <img className="logo" src="https://cdn.worldvectorlogo.com/logos/sheraton-hotels-resorts-2.svg"/>
        </SwiperSlide>
   
      </Swiper>
      </div>

    </Wrapper>)
}
const Wrapper = styled.section`
height: 90vh;
width: 100%;

.main{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
}
.title{
    
    font-size: 70px;
    color: #454a46;
    text-align: center;
    font-family: "EB Garamond", serif;
    letter-spacing: 2px;
}

.mySwiper {
  width: 70%;
  height: 350px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.logo{
    height: 100%;
    width: 100%;
    object-fit:contain;
}

.swiper-pagination-bullet {
  width: 30px; 
  height: 5px; 
  background-color: #183b7d; 
  border-radius: 0; 
  opacity: 0.5; 
}

.swiper-pagination-bullet-active {
  opacity: 1; 
}

`

export default Ourpartners;