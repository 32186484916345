import React from "react";
import styled from "styled-components";
import logo from "../kaaba.png";
import LayersIcon from "@mui/icons-material/Layers";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
function ReviewCard(props) {
  return (
    <Wrapper>
      <div className="wrapper">
        <div className="profilepic">
          <img
            className="avatar"
            src="https://dometours.com/assets/site/img/avatar-female.png"
          />
        </div>
        <div className="sidebanner">
          <LayersIcon className="logo" />

          <div className="category">HAJJ</div>
          <div className="date">2024</div>
        </div>

        <div className="upper">
          <div className="name">Junayd Akthari</div>
          <div className="reviewdate">14 OCTOBER, 2019</div>
          <div className="starsholder">
            <Stack spacing={1}>
              <Rating
                className="star"
                name="half-rating"
                defaultValue={5}
                precision={0.5}
                readOnly={true}
              />
            </Stack>
          </div>
        </div>

        <div className="lower">
          <div className="reviewtext">
            On my behalf of my family I would like to thank Ocean Breeze Travel for
            arranging our recent umrah trip. Especially brother Ibrar as he did
            a fabulous jobs in arranging our visas, hotels and transport. He
            informed us with very detailed information and would always be on
            the other side on the line when we needed help. May Allah rewards
            all of you with your efforts and for making this a lifetime
            experience! ... Read more
          </div>
        </div>

       

      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  position: relative;
  box-shadow: 10px 50px 10px rgba(0, 0, 0, 0.3);

  .wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .starsholder {
    border: 1px solid rgb(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
  }
  .star {
    color: #f2cb05;
  }
  .name {
    color: #616161;
    font-size: 45px;
    font-family: SangBleuSans, serif;
  }
  .reviewdate {
    color: #616161;
    font-size: 17px;
    margin-bottom: 15px;
  }
  .sidebanner {
    background-color: #d9048e;
    height: 160px;
    width: 60px;
    position: absolute;
    top: 0;
    right: 30px;
    border-radius: 0px 0px 5px 5px;
  }
  .profilepic {
    height: 100px;
    width: 100px;
    background-color: pink;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    left: 50px;
  }
  .avatar {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  .logo {
    height: 50px;
    width: 50px;
    color: white;
    margin-top: 7px;
  }
  .date {
    color: white;
    font-weight: 400;
    font-size: 19px;
    letter-spacing: 1px;
  }

  .category {
    color: white;
    font-weight: bold;
    font-size: 19px;
    letter-spacing: 1px;
  }
  .upper {
    height: 30%;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .lower {
    height: 50%;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .reviewtext {
    font-size: 23px;
    text-align: center;
    color: #616161;
    font-weight: 100;
    font-style: italic;
  }
`;

export default ReviewCard;
