import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TextsmsIcon from "@mui/icons-material/Textsms";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import MosqueIcon from '@mui/icons-material/Mosque';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';

function WhyChoose() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className="main">
      <div className="blurback"></div>
        <div className="imageholder">
          <img
            className="image"
            src="https://images.unsplash.com/photo-1564769625905-50e93615e769?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          />
        </div>
        <div className="textholder">
       
          <div className="inner">
            <div className="title">
              Why travel with <br />
              Cross Breeze Travel
            </div>

            <div className="pointsholder">
              <div className="point">
                <div className="pointiconholder">
                  <BedroomParentIcon className="pointicon" />
                </div>
                <div className="pointtext">
                  Best Hajj Operator Award 2017, 2018 & 2019. Our Hajj team of
                  28 experienced guides & doctors create a truly unique
                  experience
                </div>
              </div>

              <div className="point">
                <div className="pointiconholder">
                  <MosqueIcon className="pointicon" />
                </div>
                <div className="pointtext">
                  Five accompanying male scholars & female scholars providing
                  lectures and fatwas in English, Arabic and Urdu
                </div>
              </div>

              <div className="point">
                <div className="pointiconholder">
                  <EmojiEventsIcon className="pointicon" />
                </div>
                <div className="pointtext">
                  Best accommodation in Makkah & Madinah throughout the whole
                  Hajj period
                </div>
              </div>

              <div className="point">
                <div className="pointiconholder">
                  <TaskAltIcon className="pointicon" />
                </div>
                <div className="pointtext">
                  We are bonded with ATOL, IATA, ABTA and licensed with the
                  Ministry of Hajj in Saudi Arabia
                </div>
              </div>
            </div>

            {/* <div className="divider"></div> */}

            <div className="iconholder">
              <div className="item">
                <div className="itemiconholder">
                  <LocalPhoneIcon className="itemicon" />
                </div>
                <div className="itemtext">
                  <div className="maintext">Call us</div>
                  <div className="subtext">0203 4587665</div>
                </div>
              </div>

              <div className="item">
                <div className="itemiconholder">
                  <TextsmsIcon className="itemicon" />
                </div>
                <div className="itemtext">
                  <div className="maintext">Email us</div>
                  <div className="subtext">0203 4587665</div>
                </div>
              </div>

              <div className="item">
                <div className="itemiconholder">
                  <LocationOnIcon className="itemicon" />
                </div>
                <div className="itemtext">
                  <div className="maintext">Visit us</div>
                  <div className="subtext">0203 4587665</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  /* background-color: #1f4e8c; */

  .main {
    display: flex;
    flex-direction: row;
  }
  .imageholder {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
  .pointsholder {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    width: 85%;
    margin-top: 3%;
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: white;
    margin-top: 10%;
    opacity: 0.6;
  }
  .itemtext {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .maintext {
    font-size: 30px;
    color: #ecffff;
    font-family: "EB Garamond", serif;
  }

  .subtext {
    font-size: 15px;
    color: #ecffff;
    font-weight: 100;
    opacity: 0.98;
  }
  .itemiconholder {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2b519c;
    transition: background-color 0.3s ease;
  }
  .itemicon {
    font-size: 24px;
    color: whitesmoke;
  }
  .iconholder {
    width: 75%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }
  .item {
    display: flex;
    justify-content: space-between;
    width: 22%;
  }
  .point {
    display: flex;
    margin-top: 25px;
  }
  .pointicon {
    height: 35px;
    width: 35px;
    color: white;
  }
  .pointiconholder {
    height: 70px;
    width: 70px;
    background-color: #d90479;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .pointtext {
    color: white;
    font-size: 22px;
    width: 70%;
    margin-left: 20px;
    font-weight: 100;
  }
  .textholder {
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    padding-top: 5%;
    padding-bottom: 5%;
   
    position: relative;
    z-index: 3;
  
  }
  .blurback{
    height: 100%;
    width: 40%;
    position: absolute;
    z-index: 2;
    background-image: linear-gradient(to left, rgba(31, 78, 140, 0), rgba(31, 78, 140, 1));    
    backdrop-filter: blur(2px);
  }
  .image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .inner {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  .title {
    font-size: 60px;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    font-weight: 1000;
    letter-spacing: 2px;
  }

  
`;
export default WhyChoose;
