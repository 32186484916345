import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function Newscard(props) {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className="main">
        <div className="imageholder">
          <img src={props.image} className="image" />
        </div>

        <div className="textholder">
          <div className="maintitle">{props.title}</div>

          <div className="subtext">{props.subtitle}</div>
          <div className="date">{props.date}</div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 440px;
  width: 30%;
  position: relative;

  .main {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .imageholder {
    height: 50%;
    width: 100%;
  }
  .image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .textholder {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .maintitle {
    font-size: 28px;
    font-family: "EB Garamond", serif;
    color:#3E4769;
  }
  .subtext {
    font-size: 15px;
    color: #6C6C6C;
  }
  .date {
    color: #A9A8AE;
  }
`;

export default Newscard;
