import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Header from "../components/Header";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// import emailjs from "@emailjs/browser";
import Alert from "@mui/material/Alert";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";

import { faSnapchat } from "@fortawesome/free-brands-svg-icons";

function ContactUs() {
  const handleScroll = () => {
    window.scrollBy({
      top: 500,
      left: 0,
      behavior: "smooth",
    });
  };

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const form = useRef();

  const [ferror, setferror] = useState(false);
  const [lerror, setlerror] = useState(false);

  const [phoneerror, setphoneerror] = useState(false);
  const [emailerror, setemailerror] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateOnlyDigits = (input) => {
    const digitsRegex = /^\d+$/;
    return digitsRegex.test(input);
  };

  const sendEmail = (e) => {
    // e.preventDefault();
    // if (firstName !== "") {
    //   setferror(false);
    // } else {
    //   setferror(true);
    // }
    // if (lastName !== "") {
    //   setlerror(false);
    // } else {
    //   setlerror(true);
    // }
    // if (validateOnlyDigits(phonenumber)) {
    //   setphoneerror(false);
    // } else {
    //   setphoneerror(true);
    // }
    // if (validateEmail(email)) {
    //   setemailerror(false);
    // } else {
    //   setemailerror(true);
    // }
    // if (ferror && lerror && phoneerror && emailerror) {
    //   emailjs
    //     .sendForm("service_1zlrc7i", "template_cyjvrqn", form.current, {
    //       publicKey: "toiaehNzckceGcf70",
    //     })
    //     .then(
    //       () => {
    //         console.log("SUCCESS!");
    //         setShowAlert(true);
    //       },
    //       (error) => {
    //         console.log("FAILED...", error.text);
    //       }
    //     );
    // }
  };

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const accordionData = [
    {
      id: "panel1",
      summary: "What is included in a Halal holiday package?",
      details:
        " A Halal holiday package typically includes flights, halal-friendly accommodation, halal meals, and guided tours. It ensures that your dietary and religious needs are met throughout your trip",
      actions: false,
    },
    {
      id: "panel2",
      summary: " Are flights included in the package?",
      details: "Yes, all our Halal holiday packages include return flights.",
      actions: false,
    },
    {
      id: "panel3",
      summary: " What types of accommodation are provided?",
      details:
        "We provide 4-star or 5-star Halal-friendly hotels or resorts, depending on the package you choose. These accommodations cater to halal requirements and ensure a comfortable stay.",
      actions: true,
    },
  ];

  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [email, setemail] = useState("");

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setlastName(e.target.value);
  };
  const handlePhonenumberChange = (e) => {
    setphonenumber(e.target.value);
  };
  const handleEmailChange = (e) => {
    setemail(e.target.value);
  };

  return (
    <Wrapper>
      <Header />
      <div className="upper-divs">
        <div className="holders">
          <div className="large-text">Contact Us</div>
          <div className="large-text2">Contact Us</div>
          <div className="blur-bg" />
        </div>
      </div>
      <div className="spacer" />

      <div className="questions">
        <div className="main-upper">
          <div className="map-text">Frequently asked questions</div>
        </div>
        <div className="question-inner">
          <div className="accordion">
            {accordionData.map((item) => (
              <Accordion
                key={item.id}
                className="accordion-card"
                defaultExpanded={item.actions}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${item.id}-content`}
                  id={`${item.id}-header`}
                >
                  {item.summary}
                </AccordionSummary>
                <AccordionDetails>{item.details}</AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>

      <div className="main-holder">
        <div className="spacer" />

        <div className="main-upper">
          <div className="map-text">Find us on map</div>
        </div>
        <div className="spacer" />
        <div className="main-inner">
          <div className="map-holder">
            <iframe 
            className="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9429.314904538149!2d-1.7870285!3d53.7836366!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487be6c1e43bd78b%3A0xe82ccbe61779393a!2sAlmarkaz%20ul%20Islami!5e0!3m2!1sen!2s!4v1726316108001!5m2!1sen!2s" 
            loading="lazy" 
            referrerPolicy="no-referrer"></iframe>
          </div>
          <div className="spacer" />
          <div className="info-holder">
            <div className="info-inner">
              <div className="left-div">
                <div className="heading">Ocean Breeze Travel</div>
                <div className="sub-heading">
                Almarkaz ul Islami
                  <br />
                  Beckside Ln, Bradford
                  <br />
                  BD7 2JX, <br />
                  UK
                   
                </div>
              </div>

              <div className="right-div">
                <div className="heading2">CONTACT INTO</div>
                <div className="sub-heading2">
                inquiry@oceanbreezetravel.co.uk
                </div>
                <div className="sub-heading2">WHATSAPP: +44740 338 4893</div>
                <div className="icon-holder">
                  <a>
                    <FontAwesomeIcon
                      icon={faSnapchat}
                      className="social-icon4"
                    />
                  </a>

                  <a>
                    <TwitterIcon className="icons" />
                  </a>

                  <a>
                    <FontAwesomeIcon icon={faTiktok} className="social-icon4" />
                  </a>

                  <a>
                    <InstagramIcon className="icons" />
                  </a>
                  <a>
                    <LinkedInIcon className="icons" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <form className="form" ref={form} onSubmit={sendEmail}>
        <div className="actual-form">
          <div className="form-inner">
            <div className="prompt-text">
              <div className="form-prompt1">Contact Us</div>
              <div className="form-prompt2">
                Drop a message below and we aim to respone within 72 Hours
              </div>
            </div>
            <div className="spacer" />

            <div className="input-row">
              <div className="field-holder">
                <TextField
                  id="standard-basic"
                  label="First Name"
                  variant="standard"
                  className="input-field"
                  name="user_firstname"
                  value={firstName}
                  onChange={handleFirstNameChange}
                />
                {ferror ? <div>Please enter name</div> : <></>}
              </div>

              <div className="field-holder">
                <TextField
                  id="standard-basic"
                  label="Last Name"
                  variant="standard"
                  className="input-field"
                  name="user_lastname"
                  value={lastName}
                  onChange={handleLastNameChange}
                />
                {lerror ? <div>Please enter name</div> : <></>}
              </div>
            </div>

            <div className="spacer" />

            <div className="input-row">
              <div className="field-holder3">
                <TextField
                  id="standard-basic"
                  label="Email"
                  variant="standard"
                  className="input-field"
                  name="user_email"
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailerror ? <div>Please enter valid email</div> : <></>}
              </div>
            </div>

            <div className="spacer" />

            <div className="input-row">
              <div className="field-holder3">
                <TextField
                  id="standard-basic"
                  label="Phone Number"
                  variant="standard"
                  className="input-field"
                  name="user_phone"
                  value={phonenumber}
                  onChange={handlePhonenumberChange}
                />
                {phoneerror ? <div>Please enter only digits</div> : <></>}
              </div>
            </div>

            <div className="spacer" />

            <div className="input-row2">
              <div className="field-holder2">
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  defaultValue=""
                  className="input-message"
                  name="user_message"
                  label="Message"
                  variant="standard"
                />
              </div>

              <Button variant="outlined" className="buttons" type="submit">
                SEND
              </Button>
            </div>
          </div>
        </div>
      </form>
      {showAlert && (
        <Alert variant="filled" className="alert" severity="success">
          Submitted Successfully.
        </Alert>
      )}
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;
  font-family: "Montserrat", sans-serif;

  .social-icon4 {
    color: black;
    font-size: 15px;
    border-radius: 50%;
  }
  .social-icon3 {
    color: black;
    font-size: 20px;
    padding: 5px;
    height: 15px;
    width: 15px;
  }

  .input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .alert {
    position: fixed;
    top: 50px;
    right: 50px;
    transition: opacity 2s ease-in-out, transform 2s ease-in-out;
  }
  .large-text2 {
    display: none;
  }

  .questions {
    height: 100%;
    width: 100%;
    margin-bottom: 5%;
    margin-top: 5%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
  .question-inner {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;

    background-color: white;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 50px;
  }
  .accordion-card {
    background-color: #f2f2f2;
    margin: 2%;
  }
  .accordion {
    height: 100%;
    width: 85%;
    border: none;
  }

  .input-row2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    align-content: center;
  }
  .buttons {
    height: 50px;
    width: 100%;
    margin-top: 3%;
    background-color: #1328bc;
    color: white;
    font-size: 20px;
  }
  .prompt-text {
  }
  .field-holder {
    display: flex;
    flex-direction: column;
    width: 49%;
  }
  .field-holder3 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .form-prompt1 {
    font-size: 30px;
    font-weight: bold;
    color: black;
  }

  .form-prompt2 {
    margin-top: 2%;
    font-size: 18px;
    color: black;
  }
  .field-holder2 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .icon-holder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
  }
  .bg-image {
    height: 100%;
    width: 100%;
    z-index: -2;
    position: absolute;
    object-fit: cover;
  }
  .field-title {
    margin-bottom: 18px;
  }

  .actual-form {
    height: 100%;
    width: 100%;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .form-inner {
    background-color: white;
    height: 100%;
    width: 73%;
    border-radius: 15px;
    padding: 2%;
  }
  .input-field {
    width: 100%;
    color: black;
  }

  .input-field .MuiInput-underline:before {
    border-bottom: 2px solid blue;
  }

  .input-field .MuiInput-underline:hover:before {
    border-bottom: 2px solid blue;
  }

  .input-field .MuiInput-underline:after {
    border-bottom: 2px solid blue;
  }

  .input-message .MuiInputLabel-root {
    color: black; /* Change the label color */
  }

  .input-message .MuiInputLabel-root.Mui-focused {
    color: black; /* Change the label color when focused */
  }

  .input-message .MuiOutlinedInput-root .MuiOutlinedInput-input {
    color: black; /* Change the text color */
  }

  .input-black .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: grey; /* Change the default border color */
  }

  .input-message .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: black; /* Change the border color on hover */
  }

  .input-message
    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: none; /* Change the border color when focused */
  }

  .input-message .MuiInput-underline:before {
    border-bottom: 2px solid blue;
  }

  .input-message .MuiInput-underline:hover:before {
    border-bottom: 2px solid blue;
  }

  .input-message .MuiInput-underline:after {
    border-bottom: 2px solid blue;
  }

  .input-message {
    width: 100%;
    color: white;
    border: none;
  }
  .form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0%;
  }
  .main-text-form {
    text-align: center;
    font-size: 35px;
  }
  .bond-part {
    font-weight: bold;
  }
  .spacer {
    margin-top: 40px;
  }
  .icons {
    font-size: 15px;
    /* background-color: red; */
    border-radius: 50%;
    padding: 2%;
    color: #f2f2f2;
    color: black;
    /* background-color: black; */
    margin: 2%;
  }
  .map-holder {
    height: 100%;
    width: 100%;
  }
  .info-holder {
    height: 100%;
    width: 100%;
  }
  .info-inner {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .map {
    height: 500px;
    width: 100%;
    border: none;
  }
  .heading {
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  .heading2 {
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 15px;
    font-weight: bold;
    text-align: right;
  }

  .sub-heading {
    font-size: 15px;
    letter-spacing: 1px;
    margin-bottom: 15px;
  }

  .sub-heading2 {
    font-size: 15px;
    letter-spacing: 1px;
    margin-bottom: 15px;
    text-align: right;
  }

  .main-holder {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .main-upper {
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .map-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    /* text-transform: uppercase; */
  }

  .main-inner {
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: column;
  }
  .blur-bg {
    height: 100%;
    width: 100%;
    /* background-color: black; */
    position: absolute;
    z-index: -1;
    opacity: 0.5;
  }
  .package-holder {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .input-field .MuiInputLabel-root {
    color: black;
  }
  .input-field .MuiInputLabel-root.Mui-focused {
    color: black;
  }
  .input-field .MuiOutlinedInput-root .MuiOutlinedInput-input {
    color: black;
  }

  .large-text {
    

    font-size: 35px;
    width: 70%;
    text-align: center;
    color: white;
    margin-top: 7.5%;
    font-family: "Montserrat", sans-serif;
  }

  

  .upper-divs {
    height: 70vh;
    width: 100%;
  }
  .down-icon {
    font-size: 75px;
    margin-top: 45px;
    color: white;
    background-color: #1328bc;
    border-radius: 50%;
    transition: all 0.3s ease;
    background-color: #183b7d;

  }
  .down-icon:hover {
    cursor: pointer;
    background-color: white;
    transition: all 0.3s ease;
    color: #1328bc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
  }

  .holders {
    height: 100%;
    width: 100%;
    position: relative;
    /* background-color: #011526; */
    background-color: #0b155f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1f4995;

  }

  @media (max-width: 768px) {
    .holders {
      height: 100%;
      width: 100%;
      clip-path: none;
    }
    .large-text2 {
      display: inline;
      font-size: 30px;
      width: 75%;
      text-align: center;
      font-weight: 600;
      color: white;
      margin-top: 7.5%;
      font-family: "Montserrat", sans-serif;
    }
    .large-text {
      display: none;
    }
    .map-text {
      display: inline;
      font-size: 25px;
      text-align: center;
      width: 100%;
      height: 100%;
    }
    .upper-divs {
      height: 70vh;
      width: 100%;
      clip-path: none;
    }
    .large-text {
      font-size: 22px;
      width: 90%;
    }
    .main-inner {
      height: 100%;
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      margin-top: 0%;
    }
    .info-holder {
      width: 100%;
    }
    .map-holder {
      height: 100%;
      width: 95%;
      margin-bottom: 5%;
    }
    .main-upper {
      width: 90%;
      /* margin-top: 5%; */
    }
    .icon-holder {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 70%;
    }
    .actual-form {
      width: 100%;
      margin-top: 1%;
    }
    .form-inner {
      background-color: white;
      height: 100%;
      width: 85%;
      padding: 2%;
    }
    .sub-heading2 {
      text-align: left;
    }
    .heading2 {
      text-align: left;
    }
    .form {
      height: 100%;
      width: 100%;
      margin-top: 20%;
    }
    .info-inner {
      flex-direction: column;
    }
    .alert {
      top: 20px;
      right: 20px;
    }
    .questions {
      height: 100%;
      width: 100%;
      margin-bottom: 5%;
      margin-top: 5%;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
    }
    .question-inner {
      width: 85%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      background-color: white;
      padding-top: 5%;
      padding-bottom: 5%;
      border-radius: 15px;
    }
    .accordion-card {
      background-color: #f2f2f2;
      margin: 2%;
    }
    .accordion {
      height: 100%;
      width: 85%;
      border: none;
    }
    .upper-divs {
      height: 70vh;
      width: 100%;
      clip-path: none;
    }
    .holders {
      clip-path: none;
      background-color: #1328bc;
    }
    .large-text {
      font-size: 20px;
      width: 95%;
      font-family: "Montserrat", sans-serif;
    }
  }
`;
export default ContactUs;
